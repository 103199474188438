import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { FaCreditCard } from 'react-icons/fa'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import NumberFormat from 'react-number-format'
import { MdDateRange } from 'react-icons/md'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'services/auth/jwt/config'
import { useDispatch } from 'react-redux'
import { AuhMethods } from '../../../services/auth'
import { CurrentAuthMethod } from '../../../@jumbo/constants/AppConstants'

// icons
import visa from './creditCardsIcon/visa.png'
import mastercard from './creditCardsIcon/maestro.png'
import default_icon from './creditCardsIcon/credit-card.png'
import amex from './creditCardsIcon/expresso-americano.png'
import diners from './creditCardsIcon/diners.png'
import config from '../../../config'
import { useQuery } from 'hooks/useQuery'
import { sendParentEvent } from 'util/sendParentEvent'

const MySwal = withReactContent(Swal)

const icons = {
  visa: visa,
  mastercard: mastercard,
  amex: amex,
  diners: diners,
  default: default_icon,
}

const AccountID = {
  zapresponder: '3BB9840F610546AB923F3ADDCE166A2B',
  multdesk: '2E69E2AE259CE8DAE2FE2FCCF1AB44E9',
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(4),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(4),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

class NumberFormatCustom extends React.Component {
  render() {
    return (
      <NumberFormat
        class=".MuiInputBase-input .MuiInput-input"
        {...this.props}
        onValueChange={(values) => {
          this.props.onChange({
            target: {
              value: values.value,
            },
          })
        }}
        fullWidth
        marginDense
        format="####-####-####-####"
      />
    )
  }
}

class NumberFormatCustomDate extends React.Component {
  render() {
    return (
      <NumberFormat
        class=".MuiInputBase-input .MuiInput-input"
        {...this.props}
        onValueChange={(values) => {
          this.props.onChange({
            target: {
              value: values.value,
            },
          })
        }}
        fullWidth
        marginDense
        format="##/##"
      />
    )
  }
}

const PaymentForm = ({ setCurrentPlano, plano, setCurrentRequest }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const query = useQuery()
  const isIframe = query.get('iframe') === 'true'

  const [number, setNumber] = useState()
  const [cvv, setCvv] = useState()
  const [titularCartao, setTitularCartao] = useState()
  const [vencimento, setVencimento] = useState()
  const [loader, setLoader] = useState(false)

  const [errorCard, setErrorCard] = useState()
  const [errorCvv, setErrorCvv] = useState()
  const [errorTitularCartao, setErroTitularCartao] = useState()
  const [errorVencimento, setErroVencimento] = useState()

  const [bandeira, setbandeira] = useState(false)
  const [blocked, setBlocked] = useState()
  const isHml =
    window.location.href.includes('hmlapp') ||
    window.location.href.includes('localhost') ||
    window.location.href.includes('app-pre-prd')
  window.Iugu.setAccountID(AccountID[config.EMPRESA])
  window.Iugu.setTestMode(isHml)
  window.Iugu.setup()

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    fetch('https://maps.googleapis.com/maps/api/mapsjs/gen_204?csp_test=true', {
      method: 'GET',
    })
      .then((response) => {
        console.log('Adblock disabled')
        console.log(response)
        setBlocked(false)
      })
      .catch((err) => {
        setBlocked('uBlock')
        console.log('AdBlock enabled')
      })
  }, [])

  const checkErrorCard = (number) => {
    setErrorCard(!window.Iugu.utils.validateCreditCardNumber(number))
  }

  const getBandeiraCartao = (number) => {
    checkErrorCard(number)
    setbandeira(window.Iugu.utils.getBrandByCreditCardNumber(number))
    setNumber(number)
  }

  const checkValues = () => {
    var error = false

    if (cvv) {
      setErrorCvv(false)
    } else {
      error = true
      setErrorCvv(true)
    }

    if (titularCartao && titularCartao.split(' ').length >= 2) {
      setErroTitularCartao(false)
    } else {
      error = true
      setErroTitularCartao(true)
    }

    if (vencimento) {
      setErroVencimento(false)
    } else {
      error = true
      setErroVencimento(true)
    }

    if (number) {
      setErrorCard(false)
    } else {
      error = true
      setErrorCard(true)
    }

    return error
  }

  const getTokenCard = () => {
    setLoader(true)

    var erro = checkValues()

    if (erro) {
      setLoader(false)
      return erro
    }

    var number_formated = number.replace(/[^0-9]+/g, '')
    var mes = vencimento.split('/')[0]
    var ano = vencimento.split('/')[1]
    var name = titularCartao.split(' ')
    var first_name = name[0]
    var last_name = name[name.length - 1]

    var cc = window.Iugu.CreditCard(
      number_formated,
      mes,
      ano,
      first_name,
      last_name,
      cvv,
    )

    window.Iugu.createPaymentToken(cc, function(response, errors) {
      if (response.errors) {
        console.log(JSON.stringify(response.errors))
        MySwal.fire({
          title: 'Erro!',
          icon: 'error',
          text: JSON.stringify(response.errors),
        })
        setLoader(false)
      } else {
        // envia token do cartao
        console.log('Token criado:' + response.id)

        // se tem plano significa que é o primeiro cartão
        if (plano) {
          axios
            .post('plano/associarPrimeiroPlano', {
              planoId: plano.full_plan._id,
              card_credit: response.id,
            })
            .then((success) => {
              setLoader(false)
              MySwal.fire('Sucesso!', 'Dados salvos com sucesso!', 'success')
                .then((succes) => {
                  window.fbq('track', 'Purchase', {
                    value: plano.valor,
                    currency: 'BRL',
                  })
                  console.log('isIframe', isIframe)
                  if (isIframe) {
                    sendParentEvent({
                      type: 'planSelected',
                      error: false,
                    })
                  } else {
                    window.location.href =
                      config.EMPRESA === 'multdesk'
                        ? '/app/dashboards'
                        : 'https://zapresponder.com.br/obrigado-zp-responder'
                  }
                })
                .catch((err) => {
                  if (isIframe) {
                    sendParentEvent({
                      type: 'planSelected',
                      error: false,
                      redirect: '/dashboard/graphs',
                    })
                  } else {
                    window.location.href =
                      config.EMPRESA === 'multdesk'
                        ? '/app/dashboards'
                        : 'https://zapresponder.com.br/obrigado-zp-responder'
                  }
                })
            })
            .catch((err) => {
              MySwal.fire({
                title: 'Erro!',
                icon: 'error',
                text: err.response.data.message,
              })
              setLoader(false)
            })
        } else {
          axios
            .post('formaPagamentos/add', {
              card_credit: response.id,
            })
            .then((success) => {
              setLoader(false)
              setCurrentRequest(success.data)
              setCurrentPlano(false)
            })
            .catch((err) => {
              MySwal.fire({
                title: 'Erro!',
                icon: 'error',
                text: err.response.data.message,
              })
              setLoader(false)
            })
        }
      }
    })
  }

  return (
    <React.Fragment>
      {plano && (
        <Container
          maxWidth="sm"
          component="main"
          className={classes.heroContent}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Método de Pagamento
          </Typography>
        </Container>
      )}
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h4" align="center" gutterBottom>
            Dados do Cartão
          </Typography>
          <div className={classes.stepper}>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
            >
              Não se preocupe, você pode cancelar a qualquer momento.
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
            >
              A cobrança só será realizada após o perído teste.
            </Typography>
          </div>
          {plano && (
            <div className={classes.stepper}>
              <Typography variant="h6" gutterBottom>
                Plano Escolhido
              </Typography>
              <List disablePadding>
                <ListItem className={classes.listItem} key={plano.title}>
                  <ListItemText
                    primary={plano.title}
                    secondary={plano.subheader}
                  />
                  <Typography variant="body2">
                    R$ {plano.price}/{' '}
                    {plano.intervalo === 1 ? 'Mensal' : 'Trimestral'}
                  </Typography>
                </ListItem>
              </List>
            </div>
          )}
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                value={titularCartao}
                error={errorTitularCartao}
                onChange={(e) => setTitularCartao(e.target.value.toUpperCase())}
                id="cardName"
                label="Titular do cartão"
                fullWidth
                autoComplete="cc-name"
                helperText="Coloque igual está no cartão"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="input-with-icon-adornment">
                Número do Cartão*
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                fullWidth
                error={errorCard}
                value={number}
                onChange={(e) => getBandeiraCartao(e.target.value)}
                inputComponent={NumberFormatCustom}
                startAdornment={
                  <InputAdornment position="start">
                    {bandeira ? (
                      <img src={icons[bandeira] || icons['default']} />
                    ) : (
                      <img src={icons['default']} />
                    )}
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="input-with-icon-adornment">
                Vencimento*
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                fullWidth
                error={errorVencimento}
                value={vencimento}
                onChange={(e) => setVencimento(e.target.value)}
                inputComponent={NumberFormatCustomDate}
                startAdornment={
                  <InputAdornment position="start">
                    <MdDateRange />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="cvv"
                label="CVV"
                fullWidth
                error={errorCvv}
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
                autoComplete="cc-csc"
              />
            </Grid>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <img
                src="https://faturas.iugu.com/assets/flags-65345fd4e21292647e4e907154ac30c1.png"
                alt="Visa, Master, Diners. Amex"
                border="0"
              />
            </Grid>
          </Grid>
          <div className={classes.stepper}>
            <Typography
              variant="h6"
              align="center"
              style={{ color: '#e36510' }}
              component="p"
            >
              * Nossa empresa não tem acesso aos dados do cartão, os dados são
              criptografados e enviados diretamente para{' '}
              <b>
                <a
                  href="https://www.iugu.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Iugu
                </a>
              </b>
              , empresa responsável pelo processamento do pagamento!
            </Typography>
          </div>
          <div className={classes.buttons}>
            <Button
              className={classes.button}
              onClick={() => setCurrentPlano(null)}
            >
              Voltar
            </Button>
            <Button
              onClick={getTokenCard}
              variant="contained"
              color="primary"
              disabled={blocked}
              className={classes.button}
            >
              {blocked ? `Desabilite ${blocked}` : 'Salvar'}
            </Button>
          </div>
        </Paper>
      </div>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  )
}

export default PaymentForm
