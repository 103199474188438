import React, { useState, useEffect } from 'react'
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '@jumbo/utils/IntlMessages'
import CurrentPlan from './CurrentPlan'
import GridContainer from '@jumbo/components/GridContainer'
import { Grid } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CmtCard from '@coremat/CmtCard'
import CmtCardContent from '@coremat/CmtCard/CmtCardContent'
import {
  DataGrid,
  ptBR,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Plano from '../Planos'
import axios from 'services/auth/jwt/config'
import dateformat from 'dateformat'

const STATUS_DATA = {
  paid: 'Pago',
  pending: 'Pendente',
  canceled: 'Cancelado',
  in_analysis: 'Em análise',
  draft: 'Rascunho',
  partially_paid: 'Parcialmente Paga',
  refunded: 'Reemboolsada',
  expired: 'Expirada',
  in_protest: 'Em protesto',
  chargeback: 'Contestada',
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const CustomLoadingOverlay = () => {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Faturas', isActive: true },
]

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    height: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(8),
  },
}))

const theme = createMuiTheme(ptBR)

const columns = [
  { field: 'id', headerName: 'id', width: 30 },

  {
    field: 'plano',
    headerName: 'Plano',
    width: 150,
    valueGetter: (params) => `${params?.row?.plano?.nome || ''}`,
  },
  {
    field: 'valor_formated',
    headerName: 'Valor',
    width: 250,
    valueGetter: (params) =>
      `${params.row.valor_formated ||
        params.row.plano.valor.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}`,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 250,
    valueGetter: (params) => STATUS_DATA[params.row.status],
  },
  { field: 'descricao', headerName: 'Descrição', width: 250 },
  {
    field: 'dt_pagamento',
    headerName: 'Dt. Pagamento',
    type: 'date',
    width: 150,
    valueGetter: (params) =>
      `${
        params.row.dt_pagamento
          ? dateformat(params.row.dt_pagamento, 'dd/mm/yyyy')
          : ''
      }`,
  },
  {
    field: 'createdAt',
    headerName: 'Dt. inclusão',
    type: 'date',
    width: 150,
    valueGetter: (params) =>
      `${
        params.row.createdAt
          ? dateformat(params.row.createdAt, 'dd/mm/yyyy')
          : ''
      }`,
  },
]

const Invoice = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [rowSelected, setRowSelected] = useState([])
  const [openNovoPlano, setNovoPlano] = useState(false)
  const [latest_paid, setLatestPaid] = useState()
  const [prox_paid, setProxPaid] = useState()

  const [data, setData] = useState([])

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    axios
      .get('fatura/all')
      .then((success) => {
        var faturas = success.data.faturas.map((row, index) => {
          row.id = index + 1
          return row
        })
        setLatestPaid(success.data.latest_paid)
        setProxPaid(success.data.prox_paid)
        setData(faturas)
      })
      .catch((err) => err)
  }, [])

  return (
    <React.Fragment>
      {openNovoPlano ? (
        <Plano setNovoPlano={setNovoPlano} />
      ) : (
        <PageContainer
          heading={<IntlMessages id="sidebar.faturas" />}
          breadcrumbs={breadcrumbs}
        >
          <GridContainer>
            <Grid item xs={12} md={12}>
              <CurrentPlan
                setNovoPlano={setNovoPlano}
                latest_paid={latest_paid}
                prox_paid={prox_paid}
              />
            </Grid>
            <Grid item xs={12}>
              <CmtCard className={classes.cardRoot}>
                <CmtCardContent>
                  <ThemeProvider theme={theme}>
                    <DataGrid
                      rows={data}
                      columns={columns}
                      checkboxSelection
                      autoHeight
                      pageSize={15}
                      density="compact"
                      loading={loading}
                      isCellEditable={false}
                      isRowSelectable={false}
                      disableSelectionOnClick
                      onRowClick={({ row }) => {
                        if (row.url) {
                          window.open(row.url, '_blank')
                        }
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                      components={{
                        Toolbar: CustomToolbar,
                        LoadingOverlay: CustomLoadingOverlay,
                      }}
                      onSelectionModelChange={({ selectionModel }) =>
                        setRowSelected(selectionModel)
                      }
                    />
                  </ThemeProvider>
                </CmtCardContent>
              </CmtCard>
            </Grid>
          </GridContainer>
        </PageContainer>
      )}
    </React.Fragment>
  )
}

export default Invoice
